$theme-main_orange: #f0852c;
$theme-main_yellow: #eeb518;
$theme-green: #1bcc85;
$theme-light_green: #60eab2;
$theme-text_color: #666666;
$theme-gray: #bdbdbd;
$theme-light_gray: #fafafa;
$theme-mid_gray: #e8e8e8;
$btn-trans: .4s;

$breakpoints: (
    'sp-s': 'screen and (max-width: 480px)',
    'sp': 'screen and (max-width: 768px)',
    'md': 'screen and (min-width: 769px)',
    'tb': 'screen and (min-width: 1040px)',
    'pc': 'screen and (min-width: 1200px)',
) !default;