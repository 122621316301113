@mixin fontCalc($num) {
  $rem: $num / 16;
  font-size: #{$rem}rem;
}

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mqParam($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
