@charset "utf-8";

@import '../_inc/variables.scss';
@import '../_inc/mixins.scss';

body {
  position: relative;
}

.global-header {
  width: 100%;
  color: #FFF;
  @include mq() {
    position: absolute;
    box-shadow: none;
    z-index: 5555;
  }
  hr {
    background: #FFF;
  }
}

.header-logo {
  > a{
    @include mq() {
      background-image: url(../common/img/logo-header.png);
    }
  }
}

.global-nav.-pc {
  .list-global__nav {
    > li {
      &:nth-child(1) {
        > a {
          background-color: $theme-main_orange;
          border: 5px solid #eeb518;
        }
      }
      > a {
        color: #FFF;
      }
    }
  }
}

.bx-wrapper {
  margin-bottom: 0;
  border: none;
  box-shadow: none;
  img {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

.hero-img {
  max-height: 700px;
  > .hgroug {
    position: absolute;
    bottom: 17.5%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    transition: .5s;
    @include mq() {
      bottom: 12.5%;
    }
    @include mq(tb) {
      bottom: 15%;
    }
    @include mq(pc) {
      bottom: 20%;
    }
    h1 {
      margin: 10px 0 0 0;
      @include mq(tb) {
        margin: 15px 0 0 0;
      }
    }
    h2 {
      margin: 0 20px;
      @include mq() {
        margin: 0;
      }
    }
    .circle-300 {
      position: relative;
      display: block;
      width: 20%;
      min-width: 90px;
      height: auto;
      border-radius: 100%;
      border-style: solid;
      border-width: 3px;
      transition: $btn-trans;

      &::before{
        display: block;
        content: "";
        padding-top: 100%;
      }
      @include mq() {
        width: 20%;
        min-width: inherit;
        max-width: 200px;
      border-width: 5px;
      }
      @include mq(tb) {
        width: 25%;
        max-width: 300px;
        border-width: 10px;
        border-radius: 100%;
      }
    }
  }
}

.header-job__search {
  > h1 {
    position: relative;
    margin: 0;
    color: $theme-green;
    @include fontCalc(20);
    font-weight: bold;
    @include mq() {
      @include fontCalc(26);
    }
    > .circle-100 {
      margin-top: -25px;
      @include mq() {
        margin-top: -50px;
      }
    }
  }
}

.form-job__search {
  margin-top: 1.5rem;
  @include mq() {
    margin-top: 3rem;
  }
}

.select-industory,
.select-occupation,
.select-job__area,
.input-search__word {
  min-height: 45px;
  height: 100%;
  border-bottom: 1px solid $theme-gray;
}

.select-industory,
.select-occupation,
.select-job__area {
  background: url(../common/img/form-icon__select.png) no-repeat 98% center;
  background-size: 16px auto;
}

.shadow-1 + * {
  margin-top: 4px;
}

.site-role {
  > h2 {
    margin-top: 0;
    @include mq() {
      @include fontCalc(26);
    }
  }
}

.reason-column {
  padding: 3vw;
  border: 4px dotted #FFF;
  border-radius: 20px;
  @include mq(sp) {
    margin-bottom: 10px;
  }

  @include mq() {
    padding: 6vw;
    border-width: 6px;
    border-radius: 40px;
  }
  > dl {
    margin: 15px 0 0;
    @include mq() {
      margin-top: 30px;
    }
    > dt {
      margin-bottom: 0.75rem;
      @include fontCalc(18);
      font-weight: bold;
      @include mq() {
        @include fontCalc(20);
      }
    }
    > dd {
      margin: 0;
    }
  }

}

.icon-local {
  width: 48px;
  @include mq() {
    width: 74px;
  }
}

.page-link {
  padding: 0;
  > li {
    overflow: hidden;
    margin-bottom: 4%;
    background: #0b2337;
    border: 5px solid $theme-main_yellow;
    border-radius: 20px;
    transition: all 400ms 0s ease-out;
    @include mq() {
      float: left;
      margin-bottom: 2%;
      width: 49%;
      border-radius: 40px;
      &:nth-child(even) {
        float: right;
      }
    }
    * {
      transition: all 400ms 0s ease-out;
    }
    &:hover {
      box-shadow: 3px 6px 14px rgba(0, 0, 0, .24);
      img {
        transform: scale(1.16, 1.16);
        opacity: 0.7;
      }
      a > .flex {
        .btn-detail {
          background: $theme-green;
          border-color: $theme-green;
        }
      }
    }
    > a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      color: #FFF;
      > img {
        width: 100%;
        vertical-align: middle;
      }
      > .flex {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 100;
        > .title {
          margin-bottom: 10px;
          @include fontCalc(20);
          line-height: 1;
          @include mq() {
            margin-bottom: 20px;
            @include fontCalc(26);
          }
        }
        .btn-detail {
          display: inline-block;
          margin-top: 10px;
          padding: 0.375em 3em;
          border: 2px solid #FFF;
          border-radius: 2em;
          @include mq() {
            margin-top: 20px;
            padding: 0.375em 4.5em;
          }
        }
      }
    }
  }
}